<template>
  <div class="fun_template_my_page">
    <template
      v-if="is_custom"
    >
      <custom></custom>
    </template>
    <template
      v-else
    >
      <div
        class="page_header_group"
        v-if="$route.params.type == 'purchase' || $route.params.type == 'sell'"
      >
        <div class="tag_group cp"
          :class="{on: tag_type == 'template'}"
          @click="tag_type='template'"
          v-if="!package_details"
        >
          <div class="tag">任务</div>
        </div>
        <div class="tag_group cp"
          :class="{on: tag_type == 'package'}"
          @click="tag_type='package';getPackageList();search='';"
          v-if="!package_details"
        >
          <div class="tag">教学包</div>
        </div>
        <div
          class="return"
          @click.stop="package_details=false;"
          v-if="package_details"
        >
          <div class="icon cp"></div>
          <div class="text cp">返回</div>
        </div>
      </div>
      <div class="page_content_group">
        <!-- 模板 -->
        <div
          class="content_group template_content_group"
          v-show="tag_type=='template' && !package_details"
        >
          <div class="tag_wrapper">
            <div class="search_group d-flex align-center">
              <div class="search_box">
                <div class="icon"></div>
                <input
                  type="text"
                  class="search"
                  placeholder="输入内容，Enter进行搜索"
                  v-model="template_search"
                  @keyup.enter="doSearchTemplate"
                />
              </div>
              <putaway-filter-bar
                  v-if                   = "router_type === 'sell'"
                  class                  = "putaway_filter_bar"
                  ref                    = "task_putaway_filter"
                  @handleChange          = "handleChangePutawayFilter"
                  @handleOpenBantch      = "handleOpenBantch"
                  @handleCloseBantch     = "handleCloseBantch"
                  @handleSelectAll       = "handleSelectAllTask"
                  @handlePutawayByBantch = "handlePutawayTaskByBantch"
                :show_flag               = "String(show_flag)"
                :is_all                  = "is_all"
                :all_num                 = "task_all_num"
                :putaway_num             = "task_putaway_num"
                :sold_out_num            = "task_sold_out_num"
              ></putaway-filter-bar>
            </div>

            <div
              v-if="tag_list && tag_list.length"
              class="all_group cp"
              :class="{on: is_all_select}"
              @click="selectAllHandler"
            >
              <div class="icon"></div>
              <div class="text">全部标签</div>
            </div>
            <div class="all_group cp" v-else>
              <div class="text">暂无标签</div>
            </div>
            <div class="tag_group" :class="{more: more_tag}">
              <div
                class="tag un_sel cp"
                :class="{selected: item.select}"
                @click="selectTag(item, index);"
                v-for="(item, index) in tag_list"
                :key="item.id"
              >{{item.tagName}}</div>
            </div>
            <transition>
              <div class="more_group" v-show="!more_tag && 0">
                <div class="text" @click="more_tag = true;">展开更多</div>
                <div class="icon" @click="more_tag = true;"></div>
              </div>
            </transition>
          </div>
          <div class="content_wrapper">
            <div class="template_wrapper"
              id="template_wrapper"
            >
              <div class="template_group">
                <div
                  class="template_unit"
                  v-for="(item,index) in template_my_list"
                  :key="index"
                  ref="unit"
                >
                  <thumb-task
                    :item="item"
                    class="list_unit cp un_sel"
                    :menu="true"
                    :show_time="true"
                    :sell="teaching"
                    :show_tag="!teaching"
                    :show_from="!teaching"
                    :show_teaching="teaching"
                    :show_audit="teaching"
                    :show_putaway="teaching"
                    :open_select="switch_select_task"
                    :canSetStatus="true"
                    @handleClick="openTaskBox(item, !teaching)"
                    @handleAddTag="openTagBox"
                    @handleShare="shareTask"
                    @handlePutaway="handlePutaway"
                    @handleSoldOut="handleSoldOut"
                  ></thumb-task>
                </div>
              </div>
              <div
                class="more_group cp"
                v-if="load_more_template"
                @click.stop="loadMoreTemplate"
                ref="no_more"
              >
                <div class="text">展开更多</div>
                <div class="icon"></div>
              </div>
            </div>

          </div>
        </div>
        <!-- 教学包 -->
        <div
          class="content_group package_content_group"
          v-show="tag_type=='package' && !package_details"
        >
          <div class="search_group justify-start d-flex align-center">
            <div
              class="new_package cp mr-10"
              @click="openEditPackage()"
              v-if="router_type != 'purchase'"
            >
              <div class="icon"></div>
              <div class="text">新建教学包</div>
            </div>
            <div class="search_box">
              <div class="icon"></div>
              <input type="text" class="search" placeholder="输入内容，Enter进行搜索" @keyup.enter="getPackageList({keyword:search});switch_search=true;" v-model="search"/>
            </div>
            <putaway-filter-bar
                v-if                   = "router_type === 'sell'"
                class                  = "package_putaway_filter_bar"
                ref                    = "package_putaway_filter"
                @handleChange          = "handleChangePackagePutawayFilter"
                @handleOpenBantch      = "handlePackageOpenBantch"
                @handleCloseBantch     = "handlePackageCloseBantch"
                @handleSelectAll       = "handlePackageSelectAll"
                @handlePutawayByBantch = "handlePutawayPackageByBantch"
              :show_flag               = "String(package_show_flag)"
              :is_all                  = "is_package_all"
              :all_num                 = "package_all_num"
              :putaway_num             = "package_putaway_num"
              :sold_out_num            = "package_sold_out_num"
            ></putaway-filter-bar>
          </div>
          <div class="package_wrapper">
            <!-- 正常状态 -->
            <div class="package_group" v-show="!switch_search">
              <!-- 销售sell -->
              <template
                v-for="(item, index) in package_list"
              >
                <package-thumbnail
                  class="mr-20 mb-20"
                  @handleClick="openEditPackage"
                  @handleShare="touchShareBtn"
                  @handleDelete="deletePackage"
                  @handlePutaway="handlePutawayPackage"
                  @handleSoldOut="handleSoldOutPackage"
                  :key="index"
                  :menu="true"
                  :item="item"
                  :sell="sell_package"
                  :open_select="switch_select_package"
                ></package-thumbnail>
              </template>
              <!-- 加载更多 -->
              <div class="loadmore_group" v-if="loadmorePackage">
                <div class="loadmore cp" @click="doLoadmorePackage">加载更多</div>
              </div>
            </div>
            <!-- 搜索状态 -->
            <div class="package_group" v-show="switch_search">
              <!-- 销售sell -->
              <div
                class="package_item cp"
                :class="{sell: sell_package}"
                v-for="(item, index) in package_search_list"
                :key="index"
                @click="openEditPackage(item)">
                <div class="name">{{item.title || '&nbsp;'}}</div>
                <img :src="`${$img_url}${item.webpic}`" alt="" class="pic">
                <div class="price_group">
                  <div class="price">购买价: {{item.price/100}}元</div>
                  <div
                    class="status"
                    v-if="sell_package"
                  >{{item.checkstatus | checkstatus}}</div>
                </div>
                <div class="btn cp" @click.stop="deletePackage(item)">删除</div>
              </div>
              <!-- 加载更多 -->
              <div class="loadmore_group" v-if="loadmorePackage">
                <div
                  class="loadmore cp"
                  @click="doLoadmorePackage"
                  v-if="sell_package"
                >加载更多</div>
              </div>
            </div>

          </div>

        </div>
        <!-- 教学包详情 -->
        <div
          v-show="package_details"
          class="content_group package_details">
          <div class="intro_group" v-if="package_intro">
            <div class="buy_tag"></div>
            <div class="title">{{package_intro.title}}</div>
            <div class="intro_title">简介:</div>
            <div class="intro_content" v-html="package_intro.content || '暂无'">
                                  点击购买课程，可同时获得两次专家在线答疑机会。
  --------------------
  《物料管理》是会E人独立研发“会、展、活动”行业的核心实践知识模块之一，属于执行类课程
  本课程主要讲解的是会务物料的重要性、定义、管理流程、制作过程、时间规划、需求满足以及仓储运输、发放回收等知识点讲解
  --------------------
  课程定位：行业初级，项目基础认知类
  适用人群：会展相关专业在校生、初入行业的工作者
                              </div>
          </div>
          <div class="template_list_group">
            <div class="title">教学模版列表：</div>
            <div class="list_group">
              <template
                v-for="(item, index) in package_template_list"
              >
                <thumb-task
                  :key="index"
                  :item="item"
                  class="list_unit cp un_sel"
                  :menu="false"
                  :show_time="false"
                  :show_tag="false"
                  :separate="false"
                  @handleClick="openTaskBox(item)"
                ></thumb-task>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>

    <transition name="fade">
      <div class="template_add_tags" v-if="switch_template_add_tags">
        <template-add-tags
          ref="addTagBox"
          @closeTagBox="switch_template_add_tags=false;"
          @refresh="init"
        ></template-add-tags>
      </div>
    </transition>
  </div>
</template>

<script>
import Custom from '@/components/FunTemplate/Custom/Layout.vue';
import TemplateUnit from "@/components/FunTemplate/TemplateUnit.vue";
import TemplateAddTags from "@/components/FunTemplate/AddTags.vue";
import ThumbTask from '@/components/Common/ThumbTask.vue';
import PutawayFilterBar from '@/components/Putaway/FilterBar.vue';
import PackageThumbnail from '@/components/TeachingPackage/Thumbnail.vue';
import { listForClasses, PTask, PPackage } from '@/types'
import ThumbnailTask from '@/components/Thumbnail/Task'
export default {
  components: {
    Custom,
    TemplateUnit,
    TemplateAddTags,
    ThumbTask,
    PackageThumbnail,
    PutawayFilterBar,
    ThumbnailTask
  },
  data() {
    return {
      more_tag: false,
      is_all_select: true,
      tag_list: [],
      selected_tag: [],
      switch_template_add_tags: false, //添加标签switch
      tag_type: 'template',
      search: '', //教学包搜索关键词
      switch_search: false,
      router_type: this.$route.params.type,
      template_search: '',
      loadmorePackage: true, // 加载更多
      package_details: false, // 查看教学详情
      package_template_list: null, // 教学表范文列表
      package_intro: null,
      load_more_template: true, //加载更多模板
      // scrollto 配置
      options: {
        container: '#template_wrapper', //容器
        easing: 'ease-in',
        offset: 0,
        force: true,
        cancelable: true,
        onStart: function() {
          // scrolling started
        },
        onDone: function() {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      },
      loading_init: false,


      // 切换路由时需要初始化的数据

      switch_select_task: false,   // 是否开启任务选择
      show_flag         : '',      // '' 全部 0 未上架 1 上架
      task_all_num      : 0,       // 全部数量
      task_putaway_num  : 0,       // 上架数量
      task_sold_out_num : 0,       // 下架数量

      switch_select_package: false,   // 是否开启教学包选择
      package_show_flag    : '',      // '' 全部 0 未上架 1 上架
      package_all_num      : 0,       // 全部数量
      package_putaway_num  : 0,       // 上架数量
      package_sold_out_num : 0,       // 下架数量

    };
  },
  computed: {
    template_my_list() {
      return this.$store.state.template.template_my_list;
    },
    template_my_tags() {
      return this.$store.state.template.template_my_tags;
    },
    package_list(){
      return this.$store.state.package_list;
    },
    package_search_list(){
      return this.$store.state.package_search_list;
    },
    // 销售的教学包
    sell_package(){
      let status = false;
      if(this.router_type === 'sell'){
        status = true;
      }
      return status;
    },
    teaching(){
      if(this.router_type == 'sell'){
        return true;
      }
      return false;
    },
    purchase_package(){
      let status = false;

      return status;
    },
    // 是否是自定义
    is_custom(){
      const {params} = this.$route;
      const {type} = params;
      if(type == 'custom'){
        return true;
      }
      return false;
    },
    is_all(){
      const {template_my_list} = this;
      const is_all = template_my_list.every(item=>{
        return item.cf_selected;
      })
      return is_all;
    },
    is_package_all(){
      const {package_list} = this;
      if(!package_list){
        return false
      }
      const is_all = package_list.every(item=>{
        return item.cf_selected;
      })
      return is_all;
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        if(val.params.type){
          this.router_type = val.params.type;
        }
        this.init();
      },
      deep: true,
      immediate: true,
    },
    template_my_tags: {
      handler: function (val) {
        this.myTagsHandler(val);
      },
      deep: true,
    },
    search: function(val){
      if(val == ''){
        this.switch_search = false;
        if(this.package_list.length != 20){
          this.loadmorePackage = true;
        }
        this.$store.commit("set_package_search_list", null);
      }
    },
    package_list: {
      handler: function(){},
      deep: true,
    },
    package_search_list: {
      handler: function(){},
      deep: true,
    },
    tag_list: {
      handler: async function(val, old){
        // 判断是否全选
        let is_all_select = val.every( item => {
          return item.select == true;
        })
        // 没有变化
        let no_change = false;
        let val_select_all = val.every( item => {
          return item.select == true;
        })
        let val_select_none = val.every( item => {
          return item.select == false;
        })
        let old_select_all = old.every( item => {
          return item.select == true;
        })
        let old_select_none = old.every( item => {
          return item.select == false;
        })
        if((val_select_all && old_select_none) || (val_select_none && old_select_all)){
          no_change = true;
        }

        // 当前已经是全选 && 变化完还是全选的状态 || 没有变化
        if((this.is_all_select && is_all_select) || no_change){
          this.is_all_select = is_all_select;
          return;
        }else{
          this.is_all_select = is_all_select;
          //获取新数据
          this.$nextTick(async function(){
            await this.selectTemplateByTags();
          })
        }

      },
      deep: true,
    },
    template_search: function(val){
      if(val == ''){
        this.getMyTemplateList();
      }
    }
  },
  filters: {
    checkstatus: function (val) {
      let text = '';
      if(val){
        switch(val){
          case 1:
            text = '未审核';
            break;
          case 2:
            text = '审核中';
            break;
          case 3:
            text = '通过';
            break;
          case 4:
            text = '不通过';
            break;
          default:
            text = '';
            break
        }
      }
      return text;
    }
  },
  methods: {
    /**判断是否是选中的标签 */
    isSelectedTag() {},
    /**标签钩子 */
    tagHandler() {},
    /**路由类型钩子 */
    routeTypeHandler() {
      let type = this.$route.params.type;
      this.router_type = type;
    },
    /**
     * 获取我创建/接受的模板
     */
    async getMyTemplateList(obj) {
      this.$store.commit('set_template_my_list', []);
      if(!obj){
        obj = {};
        // if(!obj.type ){
      }
      obj.type = this.router_type;
      // obj.showflag = 1;
      let res = await this.$store.dispatch("get_template_my_list", obj);
      const {message, contents} = res;
      if(message === 'success'){
        const {
          taskList,
          all  : task_all_num,
          down : task_sold_out_num,
          upper: task_putaway_num,
        } = contents;
        this.task_all_num      = task_all_num;
        this.task_sold_out_num = task_sold_out_num;
        this.task_putaway_num  = task_putaway_num;
        const obj_list = this.$types.transformObjectForList({
          list: taskList,
          obj: PTask,
        })
        const list = [
          ...new Set([
            ...obj_list,
          ])
        ]
        this.$store.commit('set_template_my_list', list);
        if(!res.contents.taskList || res.contents.taskList.length < 20){
          this.load_more_template = false;
        }
        if(res.contents.taskList.length ==  20){
          this.load_more_template = true;
        }
      }
    },
    /**
     * @description: 查询更多模板
     */
    async loadMoreTemplate(){
      let {template_my_list, show_flag, router_type, template_search} = this;
      let startId = template_my_list[template_my_list.length - 1].id;
      let data = {
        type: this.router_type,
        startId,
        keyword: template_search.trim()
      };
      if(router_type === 'sell'){
        data.showflag = show_flag;
      }
      let res = await this.$store.dispatch('get_template_my_list', data);
      const {message, contents} = res;
      if(message === 'success'){
        const {
          taskList,
          all  : task_all_num,
          down : task_sold_out_num,
          upper: task_putaway_num,
        } = contents;
        this.task_all_num      = task_all_num;
        this.task_sold_out_num = task_sold_out_num;
        this.task_putaway_num  = task_putaway_num;
        const obj_list = this.$types.transformObjectForList({
          list: taskList,
          obj: PTask,
        })
        const list = [
          ...new Set([
            ...template_my_list,
            ...obj_list,
          ])
        ]
        this.$store.commit('set_template_my_list', list);

        if(!res.contents.taskList || res.contents.taskList.length < 20){
          this.load_more_template = false;
        }
        if(res.contents.taskList.length ==  20){
          this.load_more_template = true;
        }
        // if(res.contents && res.contents.taskList && res.contents.taskList.length){
        //   res.contents.taskList.forEach(item => {
        //     template_my_list.push(item);
        //   })
        // }
        this.scrollToBottom();
      }

    },
    // 滚动到底部
    scrollToBottom(){
      let element;
      if(this.$refs.no_more){
        element = this.$refs.no_more;
      }else{
        // 获取最后一个元素
        if(this.$refs.unit && this.$refs.unit.length != 0){
          element = this.$refs.unit[this.$refs.unit.length - 1];
        }
      }
      this.$scrollTo(element, 600, this.options);


    },
    // §3.1.36 我的标签查询
    getMyTemplateTags(keywords) {
      this.$store.dispatch("get_template_my_tags", { keywords });
    },
    /**
     * 处理我的标签
     */
    myTagsHandler(val){
      let tag_list = [];
      val = this.$tools.deepClone(val);
      if(val && val.length ){
        val.forEach( item => {
          // 是否已在列表中
          let isIn = this.tag_list.find(subitem=>{
            return item.tagName == subitem.tagName;
          })
          if(!isIn){
            if(this.is_all_select){
              item.select = true;
            }else{
              item.select = false;
            }
          }
          tag_list.push(item);
        })
      }
      this.$set(this, 'tag_list', tag_list);
    },
    /**选择标签
     * 全选时 点击一个单独选出来
     *
     */
    async selectTag(item, index){
      if(this.is_all_select){
        let tag_list = this.tag_list;
        tag_list.forEach(unit => {
          if(unit.tagName != item.tagName){
            unit.select = false;
          }
        })
        this.$set(this, 'tag_list', tag_list);
      }else{
        this.$set(this.tag_list[index], 'select', !item.select);
      }
    },
    /**
     * 通过tag查询模板
     */
    async selectTemplateByTags(){
      let tag_list = this.tag_list;
      let is_all_select = this.is_all_select;
      let select_none = false; //是否一个都没选
      select_none = tag_list.every( item => {
        return !item.select;
      })
      let data = {
        type: this.router_type,
      }
      if(is_all_select || select_none){
        // this.$store.dispatch('get_template_my_list');
        this.getMyTemplateList(data);
      }else{
        let tagId = '';
        tag_list.forEach( item => {
          if(item.select){
            if(tagId){
              tagId += ',';
            }
            tagId += item.id;
          }
        })
        data.tagId = tagId;
        // this.$store.dispatch('get_template_my_list', {tagId});
        this.getMyTemplateList(data);
      }
    },
    /** 全选 */
    selectAllHandler(){
      let tag_list = this.$tools.deepClone(this.tag_list);
      if(this.is_all_select){
        // 取消全选
        tag_list.forEach(item => {
          item.select = false;
        })
      }else{
        tag_list.forEach(item => {
          item.select = true;
        })
      }
      this.$set(this, 'tag_list', tag_list);
    },
    async init() {
      if(this.loading_init){
        return;
      }
      this.loading_init = true;
      this.tag_type = 'template';
      this.router_type = this.$route.params.type;
      if(this.router_type == 'custom'){
        this.$api.custom.clearMultipleSelect(); // 清除多选操作
        this.loading_init = false;
        return false
      }
      this.laad_more_template = true;
      await this.getMyTemplateList();
      await this.getMyTemplateTags();
      this.$store.commit('set_package_list', null);
      this.$store.commit('set_package_search_list', null);
      this.package_details = false;

      this.search = '';
      this.template_search = '';
      this.loading_init = false;

    },
    /**创建/编辑/ 查看教学包 */
    async openEditPackage(item) {
      // 新建教学包 / 编辑教学包
      let routetype = this.$route.params.type
      if (routetype == 'purchase') {
        // 我购买的
        let res = await this.$http.request('/projectTeach/queryPackageTaskList',{packageId:item.id})
        let { taskList } = res.contents
        if (res.message == 'success') {
          this.$set(this, 'package_intro', item)
          this.$set(this, 'package_template_list', taskList)
          this.package_details = true
        }
      } else {
        // 我销售的
        let user = this.$tools.getCookie('user')
        if (user.sign != 1) {
          // 敬请期待
          this.$openMessageBox({
            title:'提示',
            desc:'签约讲师功能暂未上线，敬请期待...',
            sure:'返回'
          })
        }else{
          this.$store.commit('set_switch_package_box', true)
          this.$store.commit('set_package_data', item || new PPackage()) //编辑 || 新建
        }
      }

    },
    /** 打开添加标签盒子 */
    openTagBox(item) {
      this.$store.commit('set_template_addtag_unit_data', Object.assign(
        {},
        item
      ));
      this.switch_template_add_tags=true;
    },

    shareTask(item){
      this.$api.share.doStartShare({data: Object.assign(
        {},
        item,
      ), type: 1});
    },
    clearPackageData(){
      this.$store.commit('set_package_list', []);
      this.$store.commit('set_package_search_list', []);
      this.package_all_num = 0;
      this.package_putaway_num = 0;
      this.package_sold_out_num = 0;
    },
    /**
     * @description: 获取教学包列表
     * @param {type}
     * @return {type}
     */
    async getPackageList(params){
      let {keyword, startId} = params || {};
      let {package_list, package_search_list, package_show_flag} = this;
      let routetype = this.$route.params.type;
      let data = {
        keyword : keyword || '',
        type    : routetype == 'purchase' ? 2: 1,
        showflag: package_show_flag,
      }
      if(startId){
        data.startId = startId;
      }
      let res = await this.$http.request('/projectTeach/queryPackage', data);
      const {message, contents} = res;
      if(message !== 'success'){
        return false;
      }
      const {
        packageList,
        all  : package_all_num,
        down : package_sold_out_num,
        upper: package_putaway_num,
      } = contents;
      this.package_all_num      = package_all_num;
      this.package_sold_out_num = package_sold_out_num;
      this.package_putaway_num  = package_putaway_num;
      const obj_list = this.$types.transformObjectForList({
        list: packageList,
        obj: PPackage,
      })
      const list = [
        ...new Set([
          ...obj_list,
        ])
      ]
      if(packageList.length < 20){
        this.loadmorePackage = false;
      }

      if(startId){
        if(keyword){
          let new_package_search_list = [
            ...new Set([
              ...package_search_list,
              ...list,
            ])
          ]
          this.$store.commit('set_package_search_list', new_package_search_list);
        }else{
          let new_package_list = [
            ...new Set([
              ...package_list,
              ...list,
            ])
          ]
          this.$store.commit('set_package_list', new_package_list);
        }
      }else{
        if(keyword){
          // this.$store.commit('set_package_search_list', packageList);
          this.$store.commit('set_package_search_list', list);
        }else{
          // this.$store.commit('set_package_list', packageList);
          this.$store.commit('set_package_list', list);
        }
      }


    },
    /**
     * @description: 加载更多教学包
     * @param {*}
     * @return {*}
     */
    async doLoadmorePackage(){
      let {search} = this;
      let params = {};
      if(search){
        params.keyword = search;
        params.startId = this.package_search_list[this.package_search_list.length - 1].id;
      }else{
        params.startId = this.package_list[this.package_list.length - 1].id;
      }
      await this.getPackageList(params);
    },
    /**
     * @description: 删除教学包
     * @param {type}
     * @return {type}
     */
    async deletePackage(item){
      this.$openConfirm({
        title: '确定删除?',
        desc: '温馨提示: 该操作无法撤销',
        sure: '删除',
        cancel: '取消',
        onSure: async () => {
          await this.$http.request('/projectTeach/deletePackage', {packageId: item.id});
          let package_list = this.package_list;
          let index = package_list.findIndex( unit => {
            return unit.id == item.id;
          })
          package_list.splice(index, 1); //删除
          // 删除搜索列表
          if(this.switch_search){
            let package_search_list = this.package_search_list;
            if(package_search_list){
              let search_index = package_search_list.findIndex( unit => {
                return unit.id == item.id;
              })
              package_search_list.splice(search_index, 1); //删除
            }
          }
        }
      })
    },
    /**搜索模板 */
    doSearchTemplate(){
      if(this.template_search != ''){
        this.getMyTemplateList({keyword: this.template_search})
      }
    },
    openTaskBox(item, jump){
      // 我销售的需要抹空
      let {type} = this.$route.params;
      let data = {
        taskId: item.id,
      }
      let config = {
        edit:true,
      }
      if(type == 'purchase'){
        data.status = 'clear';
        // config.call = true; // 购买的模板 变为调用
        config.relevancy = true; //加载关联数据
        config.view = true // 我要使用
      }else if(type == 'sell' ){
        // data.status = 'clearuser';
        config.view = true // 我要使用
      }

      if(jump!==undefined){
        config.jump = jump;
      }
      this.$api.moduleTask.openTask({taskId: item.id, config});
    },
    /**
     * @description: 点击分享按钮
     * @param {*} item
     * @return {*}
     */
    touchShareBtn(item){
      this.$api.share.doStartShare({data: item, type: 2});
      return;
    },
    async handleSoldOut(item){
      await item.doSoldOut({
        cb: ()=>{
          this.task_putaway_num -= 1;
          this.task_sold_out_num += 1;
          if(this.show_flag !== ''){
            this.removeTaskInList({field: 'showflag', value: 1}); // 留下上架的
          }
        }
      });

    },
    async handlePutaway(item){
      await item.doPutaway({
        cb: () => {
          this.task_putaway_num += 1;
          this.task_sold_out_num -= 1;
          if(this.show_flag !== ''){
            this.removeTaskInList({field: 'showflag', value: 0}); // 留下下架的
          }
        }
      });
    },
    handleChangePutawayFilter(show_flag){
      this.show_flag = show_flag;
      // 重新搜索
      this.getMyTemplateList({showflag: show_flag});
    },
    handleChangePackagePutawayFilter(show_flag){
      this.package_show_flag = show_flag;
      this.clearPackageData();
      // 重新搜索
      this.getPackageList();
    },
    /**
     * 开启批量处理
     */
    handleOpenBantch(){
      this.switch_select_task = true;
      // // console.log(this.switch_select_task);
    },
    handleCloseBantch(){
      this.switch_select_task = false;
      const {template_my_list} = this;
      template_my_list.forEach(item=>{
        // 取消全选
        item.doDeselect()
      })
    },
    handleSelectAllTask(){
      const {is_all, template_my_list} = this;
      // 取消全选
      template_my_list.forEach(item=>{
        if(is_all){
          // 取消全选
          item.doDeselect()
        }
        if(!is_all){
          // 全选
          item.doSelect();
        }
      })

    },
    handlePackageOpenBantch(){
      this.switch_select_package = true;
    },
    handlePackageCloseBantch(){
      this.switch_select_package = false;
      const {package_list} = this;
      package_list.forEach(item=>{
        // 取消全选
        item.doDeselect()
      })
    },
    handlePackageSelectAll(){
      const {is_package_all, package_list} = this;
      // 取消全选
      package_list.forEach(item=>{
        if(is_package_all){
          // 取消全选
          item.doDeselect()
        }
        if(!is_package_all){
          // 全选
          item.doSelect();
        }
      })

    },
    /**
     * 批量上架
     */
    async handlePutawayTaskByBantch(){
      let taskids = '';
      const {template_my_list, show_flag} = this;
      let change_num = 0;
      template_my_list.forEach(item=> {
        if(item.cf_selected){
          taskids += taskids ? `,${item.id}` : item.id;
          change_num += 1;
        }
      })

      if(taskids === ''){
        this.$notice({desc: '未选择范文'});
        return;
      }
      this.$openConfirm({
        title: '提示',
        desc: '是否确认上架至商城？',
        sure:'是',
        cancel:'否',
        sureType: '', // 确定按钮样式 '','warn'
        onSure:async ()=>{
          const res = await this.$global.doPost({
            url: '/projectShop/upperTasks',
            data: {taskids},
          })
          const {message} = res;
          if(message === 'success'){
            template_my_list.forEach(item=> {
              if(item.cf_selected){
                item.showflag=1;
              }
            })
            // 数量变化
            this.task_putaway_num += change_num;
            this.task_sold_out_num -= change_num;
            // 取消选择状态
            // this.switch_select_task = false;
            // console.log(this.$refs.task_putaway_filter, this.$refs);
            this.$refs.task_putaway_filter.closeBantch(); // 取消多选状态
            // 不是全部时 将当前已选择移除
            if(show_flag !== ''){
              this.removeTaskInList({field: 'showflag', value: 0}); // 留下未上架的
            }
            this.$notice({desc: '上架成功', type: 'success'})
          }
        },
        onCancel:()=>{},
      })
    },
    /**
     * @description: 移除列表中的任务
     * @param {*} params {field: 字段名, val: 字段值}
     * @return {*}
     */
    removeTaskInList(params={}){
      const {field, value} = params;
      const {template_my_list} = this;
      const new_list = [];
      template_my_list.forEach(item => {
        if(item[field] === value){
          new_list.push(item);
        }
      })

      this.$store.commit('set_template_my_list', new_list);

    },
    async handleSoldOutPackage(item){
      const {search} = this;
      // console.log(search);
      await item.doSoldOut({
        cb: ()=>{
          this.package_putaway_num -= 1;
          this.package_sold_out_num += 1;
          if(this.package_show_flag !== ''){
            if(search){
              this.removePackageInSearchList({field: 'showflag', value: 1})
            }else{

              this.removePackageInList({field: 'showflag', value: 1}); // 留下上架的
            }
          }
        }
      });

    },
    async handlePutawayPackage(item){
      const {search} = this;
      await item.doPutaway({
        cb: () => {
          this.package_putaway_num += 1;
          this.package_sold_out_num -= 1;
          if(this.package_show_flag !== ''){
            if(search){
              this.removePackageInSearchList({field: 'showflag', value: 0});
            }else{
              this.removePackageInList({field: 'showflag', value: 0}); // 留下下架的
            }
          }
        }
      });

    },

    /**
     * @description: 移除列表中的任务
     * @param {*} params {field: 字段名, val: 字段值}
     * @return {*}
     */
    removePackageInList(params={}){
      const {field, value} = params;
      const {package_list} = this;
      const new_list = [];
      package_list.forEach(item => {
        if(item[field] === value){
          new_list.push(item);
        }
      })
      // console.log(new_list)

      this.$store.commit('set_package_list', new_list);
    },
    removePackageInSearchList(params={}){
      const {field, value} = params;
      const {package_search_list} = this;
      const new_list = [];
      package_search_list.forEach(item => {
        if(item[field] === value){
          new_list.push(item);
        }
      })

      this.$store.commit('set_package_search_list', new_list);
    },

      /**
     * 批量上架
     */
    async handlePutawayPackageByBantch(){
      let packageids = '';
      const {package_list, package_search_list, package_show_flag, search} = this;
      let change_num = 0;
      if(search){
        package_search_list.forEach(item=> {
          if(item.cf_selected){
            packageids += packageids ? `,${item.id}` : item.id;
            change_num += 1;
          }
        })
      }
      if(search === ''){
        package_list.forEach(item=> {
          if(item.cf_selected){
            packageids += packageids ? `,${item.id}` : item.id;
            change_num += 1;
          }
        })
      }

      if(packageids === ''){
        this.$notice({desc: '未选择范文'});
        return;
      }
      this.$openConfirm({
        title: '提示',
        desc: '是否确认上架至商城？',
        sure:'是',
        cancel:'否',
        sureType: '', // 确定按钮样式 '','warn'
        onSure:async ()=>{
          const res = await this.$global.doPost({
            url: '/projectShop/upperPackages',
            data: {packageids},
          })
          const {message} = res;
          if(message === 'success'){
            if(search){
              package_search_list.forEach(item=> {
                if(item.cf_selected){
                  item.showflag=1;
                }
              })
            }else{
              package_list.forEach(item=> {
                if(item.cf_selected){
                  item.showflag=1;
                }
              })
            }

            // 数量变化
            this.package_putaway_num += change_num;
            this.package_sold_out_num -= change_num;
            // 取消选择状态
            // this.switch_select_task = false;
            // // console.log(this.$refs.task_putaway_filter, this.$refs);
            this.$refs.package_putaway_filter.closeBantch(); // 取消多选状态
            // 不是全部时 将当前已选择移除
            if(package_show_flag !== ''){
              if(search){
                this.removePackageInSearchList({field: 'showflag', value: 0}); // 留下未上架的
              }else{
                this.removePackageInList({field: 'showflag', value: 0}); // 留下未上架的
              }
            }
            this.$notice({desc: '上架成功', type: 'success'})
          }
        },
        onCancel:()=>{},
      })
    },

  },
  mounted() {
    this.init();
  },
};
</script>


<style lang="scss" scoped>
.fun_template_my_page {
  @include flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f5f8fa;
  position: relative;
  .page_header_group {
    @include bbox;
    @include flex;
    padding-left: 18px;
    width: 100%;
    height: 64px;
    flex-shrink: 0;
    background-color: #fff;
    .tag_group {
      @include flex;
      @include bbox;
      @include transition;
      justify-content: center;
      width: 100px;
      text-align: center;
      height: 100%;
      font-size: 16px;

      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      border-bottom: 4px solid transparent;
      &.on{
        border-bottom-color: $main_blue;
      }
    }
    .return{
      @include flex;
      .icon{
        @include background(8px, 13px);
        width: 21px;
        height: 21px;
        background-image: url(~@/assets/images/common/return.png);
      }
      .text{
        font-size: 15px;
        color: #333;
      }
    }
  }
  .page_content_group {
    @include bbox;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;
    padding: {
      top: 10px;
      bottom: 30px;
      left: 20px;
      right: 40px;
    }
    .content_group {
      @include flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
    }
    // 教学包布局
    .package_content_group{
      @include bbox;
      overflow: hidden;
      padding: 30px 0;
      align-items: flex-start;
    }
    /** 教学包item */
    .package_wrapper{
      @include bbox;
      @include flex;
      padding: 30px 0;
      width: 100%;
      height: 100%;
      flex:1;
      flex-direction: column;
    }
    .loadmore_group{
      text-align: center;
      flex-shrink: 0;
      flex-shrink: 0;
      width: 100%;

      .loadmore{
        margin:0 auto;
        padding: 0 20px;
        font-size: 14px;
        color: #ccc;
        line-height: 40px;
      }
    }
    .package_group{
      @include flex;
      @include bbox;
      @include scrollbar;
      overflow-y: auto;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      flex:1;
      padding: 10px 30px;
      padding-right: 0;
      flex-wrap: wrap;
    }
    .package_item{
      @include bbox;
      @include flex;
      position: relative;
      margin: {
        right: 20px;
        bottom: 20px;
      }
      flex-direction: column;
      padding: 20px;
      width: 280px;
      height: 238px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      align-items: flex-start;
      &.sell{
        height: 280px;
      }
      .name{
        @include single_line_intercept;
        font-size: 16px;

        font-weight: 400;
        color: #333333;
        padding-bottom: 15px;
        width: 100%;
      }
      .pic{
        width: 240px;
        height: 136px;
        border-radius: 4px;
        background-color: $img_color;
        display: block;
      }
      .replace_pic_group{
        @include flex;
        @include bbox;
        align-items: center;     /* 垂直居中 */
        width: 240px;
        padding: 10px;
        height: 136px;
        border-radius: 4px;
        background-color: #ccc;
        justify-content: center;
        .text{
          @include text-justify;
          text-align-last: center;
          color:#fff;
          font-size: 24px;
          line-height: 40px;
        }
      }
      &.sell{
        .price_group .price{
          color:#333;
        }
      }
      .price_group{
        @include flexsb;
        width: 100%;
        padding-top: 10px;
        .price{
          font-size: 14px;

          font-weight: 400;
          color: #999999;
        }
        .status{
          font-size: 14px;
          color: #05AD00;
        }
      }
      .btn_group{
        @include flex;
        @include bbox;
        border-top: 1px solid #e8eeff;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 42px;
        // background: #F4F4F4;
        background-color: #fff;
        border-radius: 0px 0px 10px 10px;
        padding: 8px 0;
      }
      .btn{
        @include flex;
        @include bbox;
        justify-content: center;
        flex: 1;
        width: 100%;
        height: 100%;
        font-size: 16px;
        color: #999999;
        &.share{
          color: #1473D0;
          border-right: 1px solid #e4e4e6;
        }
      }
    }
    /**搜索框 */
    .search_group {
      @include bbox;
      // @include flexsb;
      width: 100%;
      flex-shrink: 0;
      padding: 0 30px;
    }

    // 新建教学包
    .new_package{
      @include flex;
      height: 40px;
      background: $main_blue;
      border-radius: 4px;
      padding: 0 26px;
      color:#fff;
      font-size: 14px;
      .icon{
        @include background;
        width: 15px;
        height: 15px;
        background-image: url(~@/assets/images/common/add_white.png);
      }
      .text{
        padding-left: 8px;
      }
    }
    // 搜索
    .search_box {
      @include flex;
      @include bbox;
      height: 40px;
      background: rgba(243, 243, 243, 1);
      border-radius: 4px;
      padding: 0 10px;
      .icon {
        width: 14px;
        height: 14px;
        background: {
          image: url(~@/assets/images/common/search_icon.png);
          size: 100%;
        }
      }
      .search {
        @include placeholder(#999);
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 14px;

        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        padding-left: 10px;
        width: 200px;
      }
    }
    .tag_wrapper {
      @include bbox;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 10px 0px rgba(0, 84, 167, 0.06);
      border-radius: 10px;
      flex-shrink: 0;
      width: 100%;
      padding: 30px 0 20px;
      .all_group {
        @include flex;
        @include bbox;
        padding: 20px 30px;
        width: 100%;
        &.on {
          .icon {
            border: 0px solid transparent;
            background: {
              image: url(~@/assets/images/common/selected.png);
              size: 100%;
            }
          }
        }
        .icon {
          @include bbox;
          width: 18px;
          height: 18px;
          border: 2px solid #999;
          border-radius: 50%;
        }
        .text {
          padding-left: 10px;
          font-size: 14px;

          font-weight: 400;
          color: #333;
        }
      }
      .tag_group {
        @include flexsb;
        @include bbox;
        @include scrollbar;
        // @include transition;
        max-height: 92px; //两行
        overflow-y: auto;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 30px;
        padding-right: 10px;
        &.more {
          height: 148px; //3行
          overflow-y: auto;
        }
        .tag {
          @include bbox;
          @include transition;
          padding: 0 20px;
          line-height: 32px;
          height: 36px;
          font-size: 12px;

          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          background-color: #f5f8fc;
          border: 2px solid #eee;
          border-radius: 18px;
          margin-right: 20px;
          margin-bottom: 20px;
          &:last-child {
            margin-right: auto; // 最后一行左对齐
          }
          &.selected {
            background-color: #e5eef6;
            border: 2px solid #a9c2db;
            color: $main_blue;
          }
          &:hover{
            border: 2px solid #a9c2db;
          }
        }
      }
      .more_group {
        @include flex;
        justify-content: center;
        height: 56px;
        .text {
          font-size: 14px;

          font-weight: 400;
          color: rgba(0, 84, 167, 1);
          padding-right: 10px;
        }
        .icon {
          width: 14px;
          height: 14px;
          background: {
            image: url(~@/assets/images/common/load_more.png);
            size: 10px 6px;
            position: center;
            repeat: no-repeat;
          }
        }
      }
    }
    .content_wrapper {
      @include flex;
      @include bbox;
      flex-direction: column;
      height: 100%;
      width: 100%;
      flex: 1;
      // overflow: hidden;
      overflow-y: auto;
      padding: {
        top: 20px;
      }
      .template_wrapper{
        // @include flex;
        @include scrollbar;
        overflow-y: auto;
        // flex-direction: column;
        height: 100%;
        width: 100%;
      }
      .template_group {
        @include bbox;
        @include flex;
        justify-content: flex-start;

        // height: 100%;
        // max-height: 100%;
        width: 100%;
        padding: 10px 30px;
        // flex: 1;
        flex-wrap: wrap;

        .template_unit {
          margin: {
            right: 24px;
            bottom: 24px;
          }
        }
      }
      .more_group {
        @include flex;
        width: 100%;
        justify-content: center;
        flex-shrink: 0;
        padding: 30px 0;
        .text {
          font-size: 14px;

          font-weight: 400;
          color: rgba(0, 84, 167, 1);
          padding-right: 10px;
        }
        .icon {
          width: 14px;
          height: 14px;
          background: {
            image: url(~@/assets/images/common/load_more.png);
            size: 10px 6px;
            position: center;
            repeat: no-repeat;
          }
        }
      }
    }

    // 教学包详情
    .package_details{
      @include flex;
      flex-direction: column;
      overflow: hidden;
    }
    .intro_group{
      @include scrollbar;
      @include bbox;
      padding: 30px;
      min-height: 110px;
      max-height: 300px;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px 0px rgba(0, 84, 167, 0.06);
      border-radius: 10px;
      flex-shrink: 0;
      overflow-y: auto;
      position: relative;
      .buy_tag{
        @include background;
        width: 139px;
        height: 110px;
        top:0;
        right: 0;
        position: absolute;
        background-image: url(~@/assets/images/fun_template/buy_tag.png);
      }
      .title{
        font-size: 18px;
        color: #0054A7;
        padding-bottom: 30px;
      }
      .intro_title{
        font-size: 16px;
        color: #333;
        padding-bottom: 18px;
      }
      .intro_content{
        font-size: 14px;
      }
    }
    .template_list_group{
      @include flex;
      @include bbox;
      flex-direction: column;
      padding: 30px 0;
      overflow: hidden;
      width: 100%;
      flex: 1;
      height: 100%;
      .title{
        @include bbox;
        padding: 0 30px;
        color: #333;
        font-size: 16px;
        padding-bottom: 20px;
        flex-shrink: 0;
        width: 100%;
      }
      .list_group{
        @include flex;
        @include bbox;
        @include scrollbar;
        width: 100%;
        flex-wrap: wrap;
        padding: 0 30px;
        flex: 1;
        height: 100%;
        overflow-y: auto;
        .list_unit{
          @include bbox;
          // padding: 19px 16px;
          // width: 160px;
          // height: 140px;
          // background: #FFFFFF;
          // border: 1px solid #EEEEEE;
          // border-radius: 10px;
          margin: {
            right: 24px;
            bottom: 24px;
          }
          .name{
            @include text_justify;
            @include multi_line_intercept(6);
            text-align-last: left;
            font-size: 12px;
            line-height: 18px;
            color:#333;
          }

        }
      }
    }
  }
  .template_add_tags {
    @include flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgba( 0,0,0,0.3);
  }
  .putaway_filter_bar{
    flex: 1;
  }
  .package_putaway_filter_bar{
    flex: 1;
  }
}
</style>
